import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { MainBanner } from "../components/banners/main-banner"

import { MainLayout } from "../components/layouts/layout"
import MainSection from "../components/sections/main-section"
import SEO from "../components/seo"
import TextBlock from "../components/textblocks/text-block"

export default function Home({ data }) {
  return (
    <MainLayout activeNav="/products">
      <SEO
        title="Fornyelse"
        description="Hos Jysk Køkkenfornyelse har vi alt et stort udvalg -> Låger, Bordplader, Greb, Håndtag og Håndvaske"
      />
      <MainBanner
        image={data.banner.childImageSharp.base64.base64}
        title="Stort udvalg ved Jyskkf"
        description="Vi har et stort udvalg af bla. låger, bordplader, armaturer"
      />
      <MainSection backgroundColor="">
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <TextBlock
                filled
                linkTo={[
                  { link: "/laager/", buttonText: "Låger" },
                  { link: "/laage-farver/", buttonText: "Farver" },
                ]}
                title="Låger"
                tag="Se alle vores .."
              >
                <p>
                  Når du skal vælge dine nye låge- og skuffefronter, er der 2
                  ting du skal vælge. Det ene er farven og det andet er
                  profilen/udskæringen af lågen.
                </p>
                <p>
                  Hvad enten du er til det klassiskeMari landkøkken med
                  fræsninger eller den stilrene og blanke overflade, kan Jysk
                  Køkkenfornyelse levere netop den kombination, som du ønsker.
                </p>
                <p>Herunder kan du se de mange kombinationsmuligheder.</p>
              </TextBlock>
            </Col>
            <Col xs={12} md={6}></Col>
          </Row>
        </Container>
      </MainSection>
      <MainSection backgroundColor="rgb(51,51,51)">
        <Container>
          <Row>
            <Col xs={12} md={6}></Col>
            <Col xs={12} md={6}>
              <TextBlock
                color="white"
                linkTo={[
                  { link: "/laminat/", buttonText: "Laminat" },
                  { link: "/komposit/", buttonText: "Komposit" },
                  { link: "/granit/", buttonText: "Granit" },
                  { link: "/trae-bordplader/", buttonText: "Træ" },
                ]}
                title="Bordplader"
                tag="Se alle vores .."
              >
                <p>
                  bordplade er mere end ‘bare en bordplade‘. Bordpladen er et
                  vigtigt finish, der er med til at skaber et udtryk, vi ønsker
                  at vise i rum, der bruges hver eneste dag.
                </p>
                <p>
                  Der findes mange forskellige designs og materialer med
                  forskellige egenskaber. Nogle bordplader er 100%
                  naturprodukter, som giver varme og sjæl men kræver
                  vedligeholdelse. Andre er stilrene og produceret med den
                  nyeste teknologi og med fokus at skabe praktiske overflader.
                </p>
                <p>
                  Herunder finder du en del af vores produktsortiment. Vi håber
                  du find inspiration til farver og materialevalg der passer til
                  netop din stil.
                </p>
              </TextBlock>
            </Col>
          </Row>
        </Container>
      </MainSection>
      <MainSection>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <TextBlock
                filled
                linkTo={[{ link: "/haandvaske/", buttonText: "Håndvaske" }]}
                title="Håndvask"
                tag="Se alle vores .."
              >
                <p>Her kan du se vores store udvalg af håndvaske.</p>
              </TextBlock>
            </Col>
            <Col xs={12} md={6}></Col>
          </Row>
        </Container>
      </MainSection>
      <MainSection backgroundColor="rgb(51,51,51)">
        <Container>
          <Row>
            <Col xs={12} md={6}></Col>
            <Col xs={12} md={6}>
              <TextBlock
                color="white"
                linkTo={[
                  { link: "/armaturer/", buttonText: "Køkkenarmaturer" },
                ]}
                title="Armaturer"
                tag="Se alle vores .."
              >
                <p>
                  Vi anbefaler alle vores kunder til selv at købe et armatur til
                  det/dit/jeres fornyede køkken
                </p>
                <p>
                  men vi ville gerne give jer en mulighed for at se hvilke
                  modeller der findes, så i kan kigge løs her under.
                </p>
              </TextBlock>
            </Col>
          </Row>
        </Container>
      </MainSection>
      <MainSection>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <TextBlock
                filled
                linkTo={[{ link: "/greb/", buttonText: "greb" }]}
                title="Greb - Håndtag"
                tag="Se alle vores .."
              >
                <p>
                  Vi anbefaler alle vores kunder til selv at købe et armatur til
                  det/dit/jeres fornyede køkken
                </p>
                <p>
                  men vi ville gerne give jer en mulighed for at se hvilke
                  modeller der findes, så i kan kigge løs her under.
                </p>
              </TextBlock>
            </Col>
            <Col xs={12} md={6}></Col>
          </Row>
        </Container>
      </MainSection>
    </MainLayout>
  )
}

export const query = graphql`
  {
    banner: file(relativePath: { eq: "banners/kitchen_black.jpg" }) {
      id
      childImageSharp {
        base64: sizes(base64Width: 1600, maxHeight: 800, quality: 100) {
          base64
        }
      }
    }
    imgOne: file(relativePath: { eq: "bil_1.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 250, maxHeight: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 300, width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    imgTwo: file(relativePath: { eq: "bil_2.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 250, maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 300, width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
