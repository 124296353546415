import React from "react"

import Styled from "styled-components"
import { JkfButton } from "../buttons/jkf-button-link"

const BlockText = Styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;
    padding: 0 20px;
    color: ${props => props.color};

    h2 {
        text-transform: uppercase;
  
        
        font-size: 1.3rem;
        margin-bottom: 0;
    }

    hr {
        max-width: 50%;
        min-width: 40%;
       margin-left: 0;
       background-color: ${props => props.theme.secondaryColor};
       margin-top: 0.3rem;
    
    }

    p {
        
        font-size: 0.9rem;
    }

    p.tag {
        color: ${props => props.theme.secondaryColor};
        text-transform: uppercase;
        font-weight: 600;
        transform: scale(1, 1.2);
        
    }

    @media (max-width: 768px) {
    hr {
       min-width: 100%;
    }
  }
`

const TextBlock = ({
  linkTo,
  title,
  tag,
  children,
  buttonText,
  theme,
  color,
  filled,
}) => {
  return (
    <BlockText className="text-center text-md-left" color={color}>
      <h2>{title}</h2>
      <hr></hr>
      {children}
      <p className="tag">{tag}</p>
      <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
        {linkTo.map(link => (
          <JkfButton className="mr-1 mt-1" filled={filled} to={link.link}>
            {link.buttonText}
          </JkfButton>
        ))}
      </div>
    </BlockText>
  )
}

export default TextBlock
